/*
PRODUCTS:
    SERP = 1
    CONVERSION_OPTIMIZATION = 2
    TOP_POSITIONING = 4
    TRAFFIC = 8
    ANALYTICS = 16
    COMMUNICATION = 32
    BASIC = 64
*/
export default function useCampsiteDetail({
  campsite: pCampsite = null,
  preEnterData: pPreEnterData = null,
}: {
  campsite: MaybeRefOrGetter<any | null>;
  preEnterData?: MaybeRefOrGetter<any | null>;
}) {
  const campsite = toRef(pCampsite);
  const preEnterData = toRef(pPreEnterData);

  /********************
   * COMPOSITIONS      *
   ********************/
  const backendStore = useBackendStore();
  const getRouteBaseName = useRouteBaseName();
  const route = useRoute();
  const { locale } = useI18n();

  /********************
   * REFS & VARS       *
   ********************/
  const purchases = computed(() => {
    return campsite.value?.purchases || [];
  });

  const hasTrafficProduct = computed(() => {
    return hasProduct(8);
  });

  const hasPpc = computed(() => {
    return purchases.value.includes(256);
  });

  /********************
   * FUNCTIONS         *
   ********************/
  function hasProduct(num) {
    return (preEnterData.value?.purchases || purchases.value).includes(num);
  }

  function ppcWebsiteLink(zone = '') {
    if (!hasPpc.value) {
      return false;
    }

    const identifier = `${getRouteBaseName(route)}-${zone}`;
    const lang = locale.value;
    const slug = route.params.identifier;

    return `${backendStore.url}/click/?lang=${lang}&slug=${slug}&link_identifier=${identifier}`;
  }

  /********************
   * SCROLLING         *
   ********************/
  const scrollToRefInterval = ref<number | null>(null);
  const sectionRefs = reactive<Record<number | string, HTMLElement>>({});

  function scrollToRef(event: MouseEvent | null, ref: keyof typeof sectionRefs) {
    if (event) {
      event.preventDefault();
    }

    if (!ref) {
      return;
    }

    if (!sectionRefs[ref]) {
      if (scrollToRefInterval.value) {
        clearInterval(scrollToRefInterval.value);
      }

      scrollToRefInterval.value = setInterval(() => {
        if (sectionRefs[ref]) {
          clearInterval(scrollToRefInterval.value);
          scrollToRef(event, ref);
        }
      }, 50);
      setTimeout(() => {
        clearInterval(scrollToRefInterval.value);
      }, 5000);
      return;
    }

    const element = sectionRefs?.[ref]?.$el || sectionRefs[ref];
    if (!element) {
      return;
    }
    const scrollDistance = getOffsetTop(element) - sectionRefs.subnav?.$el.offsetHeight;
    window.scroll({ top: scrollDistance, behavior: 'smooth' });
  }

  return {
    hasProduct,
    hasTrafficProduct,
    ppcWebsiteLink,
    scrollToRef,
    sectionRefs,
  };
}
